import { PageSelector } from "../../fields";

export const Footer = {
  label: "Global Settings: Footer",
  name: "settings_footer",
  file: "cms/content/settings/footer.json",
  fields: [
    {
      label: "Main Links",
      name: "mainLinks",
      widget: "list",
      fields: [
        {
          label: "Label",
          name: "label",
          widget: "text",
          required: false
        },
        { ...PageSelector, required: true },
      ]
    },
    {
      label: "Columns",
      name: "columns",
      widget: "list",
      fields: [
        {
          label: "Title",
          name: "title",
          required: false,
          widget: "text"
        },
        {
          label: "Links",
          name: "links",
          widget: "list",
          fields: [
            { label: "Label", name: "label", widget: "text", required: false },
            { label: "Link type", name: "link_type", widget: "select", options: ["Page","Article","Url"], required: false },
            {...PageSelector, required: false },
            {
              label: "Article",
              name: "article",
              default:".forestry/content/blog-articles/featured-article-title-max-148ch.test.md",
              required: false,
              widget: "relation",
              collection: "blog_articles",
              search_fields: ["title"],
              value_field: "{{slug}}",
              display_fields: ["title"],
            },
            {
              label: "Url",
              name: "url",
              widget: "text",
              required: false,
            }
          ]
        }
      ]
    },
    {
      label: "Social Title",
      name: "socialTitle",
      required: false,
      widget: "text"
    },
    {
      label: "Social Links",
      name: "socialLinks",
      required: false,
      widget: "list",
      fields: [
        {
          label: "Label",
          name: "label",
          widget: "text",
          required: false
        },
        {
          label: "Icon",
          name: "icon",
          widget: "file"
        },
        {
          label: "Url",
          name: "url",
          widget: "text"
        },
      ],
    },
    {
      label: "Contact Numbers",
      name: "contactNumbers",
      widget: "list",
      fields: [
        {
          label: "text",
          name: "text",
          required: false,
          widget: "text"
        },
        {
          label: "Text Number",
          name: "textNumber",
          widget: "text"
        },
        {
          label: "Link Number",
          name: "linkNumber",
          widget: "text"
        }
      ]
    },
    {
      label: "Contact Emails",
      name: "contactEmails",
      widget: "list",
      fields: [
        {
          label: "Label",
          name: "label",
          required: false,
          widget: "text"
        },
        {
          label: "Email",
          name: "email",
          widget: "text",
          required: false,
        },
      ]
    },
    {
      label: "Copyright",
      name: "copyright",
      required: false,
      widget: "text"
    },
    {
      label: "Terms Conditions",
      name: "termsConditions",
      required: false,
      widget: "text"
    },
    {
      ...PageSelector,
      label: "Terms Conditions Link",
      name: "termsConditionsLink",
      required: false
    },
    {
      label: "Privacy Policy",
      name: "privacy_policy",
      required: false,
      widget: "text"
    },
    {
      ...PageSelector,
      label: "Privacy Policy link",
      name: "privacy_policy_link",
      required: false
    },
  ]
}
